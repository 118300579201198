import Link from 'next/link'
import React from 'react'
import {useTranslation} from 'next-i18next'
import Image from 'next/image'
import {renderRichText, storyblokEditable} from '@storyblok/react'
import classNames from 'classnames'
import {FooterStoryblok} from 'types/storyblok-types'
import linkTypeChecker from 'util/linkTypeChecker'
import {useRouter} from 'next/router'

type FooterProps = {
  footer: FooterStoryblok
}
const Footer = ({footer}: FooterProps) => {
  const entityInformation = renderRichText(footer.entity_information, {})

  const {i18n} = useTranslation()
  const locale = i18n?.language
  const {t} = useTranslation()
  const router = useRouter()

  const displayPreferenceCenter = () => {
    if (typeof window !== undefined && window.OneTrust) {
      window.OneTrust.changeLanguage(locale)
      window.OneTrust.ToggleInfoDisplay()
    }
  }

  const footerColumnClass = classNames(
    'my-10 w-80 text-sm text-dats-s5 md:my-0 md:mb-10 lg:w-1/4 md:w-1/3 lg:pr-8 xl:w-1/5',
  )

  const sanitizeUrl = (url: string) => {
    if (router.asPath?.includes('xtra/login')) {
      // Storyblok returns default language URLs (Dutch) without
      // the locale and without a prefix slash. But for FR it does
      // include the locale with a prefix slash.
      // The prefix slash is required on the xtra/login page to make
      // sure all links remain relative to the root of the page.
      // Therefore we add it manually if needed.
      if (!url.startsWith('/') && !url.startsWith('http')) {
        return `/${url}`
      }
    }
    return url
  }

  return (
    <>
      <footer
        data-tms-navigation-section="footer"
        {...storyblokEditable(footer)}
        className="mt-[100px] bg-dats-s10 px-5 py-10 lg:px-[100px]"
      >
        {footer.group_links && footer.group_links?.length > 0 ? (
          <div className="md:flex md:flex-wrap">
            {footer.group_links?.map(groupLink => (
              <ul key={groupLink._uid} className={footerColumnClass}>
                <li className="font-bold">{groupLink.title}</li>
                {groupLink.group_links_item?.map(linkItem => (
                  <li
                    key={linkItem._uid}
                    {...storyblokEditable(linkItem)}
                    className="pt-6"
                  >
                    <Link
                      className="font-normal text-dats-s5 no-underline"
                      href={sanitizeUrl(linkTypeChecker(linkItem.url))}
                      target={linkItem.url?.target}
                    >
                      {linkItem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
            {footer.social_links && footer.social_links.length > 0 ? (
              <div className={footerColumnClass}>
                <span className="mb-5 block font-bold">
                  {t('label_footer_social_media')}
                </span>
                <ul className="flex">
                  {footer.social_links.map(socialLink => (
                    <li
                      key={socialLink._uid}
                      {...storyblokEditable(socialLink)}
                      className="mr-3"
                    >
                      <Link
                        href={sanitizeUrl(linkTypeChecker(socialLink.url))}
                        className="relative block size-5 object-fill"
                        aria-label="social link"
                        target={socialLink.url.target}
                      >
                        <Image
                          src={socialLink.icon?.filename}
                          fill
                          alt={socialLink.icon?.alt ? socialLink.icon?.alt : ''}
                        />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        ) : null}

        <span
          className="block text-sm font-normal text-dats-s5 md:text-center"
          dangerouslySetInnerHTML={{__html: entityInformation}}
        ></span>
      </footer>
      <footer {...storyblokEditable(footer)}>
        <div className="flex flex-col justify-between bg-dats-p1 px-7 py-6 text-white lg:flex-row lg:px-[100px]">
          <div className="relative mb-10 h-4 w-32 lg:mb-0">
            <Image
              src={footer.footer_logo.filename}
              alt="colryut logo"
              fill
              unoptimized={true}
            />
          </div>
          <div className="flex-wrap text-sm [&>a]:my-2 [&>a]:font-normal [&>a]:text-white [&>a]:no-underline md:[&>a]:my-0">
            <span className="mb-3 mr-3 inline-block md:mb-0 lg:mx-3">
              © {new Date().getFullYear()} Colruyt Group
            </span>

            <Link
              href={sanitizeUrl(
                linkTypeChecker(footer.general_terms_and_conditions),
              )}
              target="_blank"
              className="mr-3 inline-flex cursor-pointer before:mr-3 before:content-['|']"
            >
              {t('label_general_legal')}
            </Link>

            <Link
              href={sanitizeUrl(linkTypeChecker(footer.privacy_statement))}
              className="mr-3 inline-flex cursor-pointer before:mr-3 before:content-['|']"
              target={footer.privacy_statement?.target}
            >
              {t('label_privacy_statement')}
            </Link>

            {footer.cookie_policy ? (
              <Link
                href={sanitizeUrl(linkTypeChecker(footer.cookie_policy))}
                className="mr-3 inline-flex cursor-pointer before:mr-3 before:content-['|']"
              >
                {t('label_cookie_policy')}
              </Link>
            ) : null}

            <button
              className="mr-3 inline-flex cursor-pointer before:mr-3 before:content-['|']"
              onClick={displayPreferenceCenter}
            >
              {footer.cookies ?? 'Cookies'}
            </button>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
