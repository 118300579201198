import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'

type HeaderLoginProfessionalProps = {
  cta_url?: string
}

function HeaderLoginProfessional({cta_url}: HeaderLoginProfessionalProps) {
  const {t} = useTranslation()

  return (
    <>
      <Link href={cta_url ?? '#'} className="mr-4 no-underline" >
        <button
          className="flex h-[40px] w-auto items-center justify-center space-x-[10px] rounded-dats border border-dats-s3 px-[15px] hover:border-dats-p2 hover:bg-dats-s4"
          type="button"
        >
          <Image
            src="/images/icon-professional-login.svg"
            alt=""
            width={20}
            height={20}
            unoptimized={true}
          />

          <p className="font-body text-base font-semibold">
            {t('label_login_professional')}
          </p>
        </button>
      </Link>
    </>
  )
}

export default HeaderLoginProfessional
