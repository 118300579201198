import {Metadata} from 'next'
import {TranslatedSlug} from 'types/types'
import HeaderContainer from './Header/HeaderContainer'
import {pageTypes} from 'pages/[[...slug]]'
import Footer from './Footer'
import {UserType, useSessionContext} from 'context/SessionContextProvider'
import AlertContainer from './Alert'
import {useRouter} from 'next/router'
import findAndReplaceString from 'util/findAndReplaceString'
import {Segment} from 'util/slugHelpers/segment'

export const metadata: Metadata = {
  title: 'DATS 24',
  description: 'DATS 24 Website',
}

type Layout = Pick<
  pageTypes,
  'footer' | 'global_alert' | 'local_alert' | 'header' | 'mainMenu'
> & {
  children?: React.ReactNode
  translatedSlugs?: TranslatedSlug[]
  storyTags?: string[]
  removeHeaderAndFooter: boolean
}

const Layout = ({
  children,
  mainMenu,
  global_alert,
  local_alert,
  header,
  translatedSlugs,
  footer,
  removeHeaderAndFooter,
}: Layout) => {
  const selectedUserType = useSessionContext().selectedUserType
  const router = useRouter()

  const sanitizeData = (data: any) => {
    const slug = router.asPath
    const splittedSlug = slug.split('/')
    const language = splittedSlug[0]

    findAndReplaceString(
      data,
      'common',
      selectedUserType === UserType.individual
        ? Segment.particulier
        : language === 'nl'
          ? Segment.professional
          : Segment.professionnel,
    )
    return data
  }
  const mainMenuBasedOnTag =
    selectedUserType === UserType.individual
      ? sanitizeData(mainMenu.private)
      : sanitizeData(mainMenu.professional)

  const headerBasedOnTag =
    selectedUserType === UserType.individual
      ? sanitizeData(header.individual)
      : sanitizeData(header.professional)

  const footerBasedOnTag =
    selectedUserType === UserType.individual
      ? sanitizeData(footer.individual)
      : sanitizeData(footer.professional)

  return (
    <>
      <div className="relative flex min-h-screen flex-col overflow-hidden">
        {!removeHeaderAndFooter ? (
          <header className="w-full">
            {global_alert ? <AlertContainer blok={global_alert} /> : null}
            {local_alert ? <AlertContainer blok={local_alert} /> : null}
            <HeaderContainer
              header={headerBasedOnTag.content}
              translatedSlugs={translatedSlugs}
              mainMenu={mainMenuBasedOnTag}
            />
          </header>
        ) : null}
        <div className="flex w-full grow flex-col md:flex-row">
          <main className="grow">{children}</main>
        </div>
        {process.env.NEXT_PUBLIC_FEATURE_ERP && (
          <>
            <div className="rounded-tl-20 fixed bottom-0 right-0 z-50 bg-pink-600 px-4 py-2 text-xs text-white">
              Feature - ERP
            </div>
          </>
        )}
        {!removeHeaderAndFooter ? <Footer footer={footerBasedOnTag} /> : null}
      </div>
    </>
  )
}

export default Layout
