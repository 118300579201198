import {storyblokEditable} from '@storyblok/react'
import classNames from 'classnames'
import Link from 'next/link'
import {useRouter} from 'next/router'
import React, {useEffect, useState} from 'react'
import {MainMenuStoryblok} from 'types/storyblok-types'
import linkTypeChecker from 'util/linkTypeChecker'

type PrimaryNavPropsType = {mainMenu: MainMenuStoryblok}

const PrimaryNav = ({mainMenu}: PrimaryNavPropsType) => {
  const filteredMainMenu = mainMenu.main_menu_item?.filter(
    item => !item.mobile_only,
  )

  const router = useRouter()

  const [scrollValue, setScrollValue] = useState<number>(0)
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>('down')

  const sanitizeUrl = (url: string) => {
    if (router.asPath?.includes('xtra/login')) {
      // Storyblok returns default language URLs (Dutch) without
      // the locale and without a prefix slash. But for FR it does
      // include the locale with a prefix slash.
      // The prefix slash is required on the xtra/login page to make
      // sure all links remain relative to the root of the page.
      // Therefore we add it manually if needed.
      if (!url.startsWith('/') && !url.startsWith('http')) {
        return `/${url}`
      }
    }
    return url
  }

  const sanitizeActiveMenuItemLinkForComparison = (url: string) => {
    if (url.endsWith('/')) {
      return url.slice(0, -1)
    }
    return url
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScrollDirection)

    return () => {
      window.removeEventListener('scroll', handleScrollDirection)
    }
  }, [])

  function handleScrollDirection() {
    setScrollValue(prev => {
      let previousValue = prev ? prev : 0
      if (previousValue < window.scrollY) {
        setScrollDirection('down')
      } else if (previousValue > window.scrollY) {
        setScrollDirection('up')
      }
      return window.scrollY
    })
  }

  const heightOfTopHeader = 48

  const primaryMenuItemClass = (link: string) => {
    return classNames(
      'px-5 py-4 block hover:underline underline-offset-[5px] hover:font-bold decoration-2 text-base decoration-dats-p1',
      {
        'font-semibold text-dats-s3 no-underline': !router.asPath.includes(
          sanitizeActiveMenuItemLinkForComparison(link),
        ),

        'no-underline font-bold text-dats-s3': router.asPath.includes(
          sanitizeActiveMenuItemLinkForComparison(link),
        ),
      },
    )
  }

  const secondaryMenuItemClass = classNames(
    'group/secondary-item group m-3 rounded-dats p-4 hover:bg-dats-border-s2/50 h-full text-left flex justify-top flex-col w-full',
  )

  const secondaryMenuClass = classNames(
    'invisible absolute left-0 top-[52px] z-50 flex w-full justify-center border-b border-b-dats-border-gray bg-white shadow-sm group-hover/primary-item:visible bg-green-500',
  )

  const secondaryMenuItemLinkClass = classNames(
    'mb-3 block font-bold text-dats-s3',
  )

  const secondaryMenuItemDescriptionClass = classNames(
    'block select-none text-xs font-normal text-dats-s5',
  )

  const primaryNavWrapper = classNames(
    ' md:bg-dats-s10 z-50 transition-opacity relative',
    {
      'opacity-0':
        scrollDirection === 'down' && scrollValue > heightOfTopHeader,
    },
    {
      '!fixed top-0 pl-4 pr-0 w-full left-0 opacity-100':
        scrollDirection === 'up' && scrollValue > heightOfTopHeader,
    },
  )

  const secondaryMainMenuItemWrapperClass = (menuItems: number) => {
    if (menuItems > 4) {
      return `md:w-1/4 lg:w-1/4`
    } else {
      return `md:w-1/${menuItems} lg:w-1/${menuItems}`
    }
  }

  return (
    <>
      <nav
        className={primaryNavWrapper}
        {...storyblokEditable(mainMenu)}
        data-tms-navigation-section="main navigation"
      >
        <ul
          className="hidden justify-center border-y border-dats-s6/10 md:flex"
          id="fixed-header"
        >
          {filteredMainMenu
            ? filteredMainMenu?.map(menuItem => (
                <li
                  key={menuItem._uid}
                  {...storyblokEditable(menuItem)}
                  className=" group/primary-item"
                >
                  <Link
                    href={
                      linkTypeChecker(menuItem.url)
                        ? sanitizeUrl(linkTypeChecker(menuItem.url))
                        : '#'
                    }
                    target={menuItem.url?.target}
                    className={primaryMenuItemClass(
                      sanitizeUrl(linkTypeChecker(menuItem.url)),
                    )}
                  >
                    {menuItem.label}
                  </Link>
                  {menuItem.sub_menu_item &&
                  menuItem.sub_menu_item.length > 0 ? (
                    <div
                      className={secondaryMenuClass}
                      data-tms-navigation-section="subnavigation"
                    >
                      <div className="flex max-w-7xl flex-wrap justify-center p-6">
                        {menuItem.sub_menu_item.map(childItem => {
                          return (
                            <div
                              key={childItem._uid}
                              // {...storyblokEditable(childItem)}
                              className={secondaryMainMenuItemWrapperClass(
                                menuItem.sub_menu_item?.length ?? 1,
                              )}
                            >
                              <Link
                                href={sanitizeUrl(
                                  linkTypeChecker(childItem.url),
                                )}
                                className="no-underline"
                                target={childItem.url?.target}
                              >
                                <button className={secondaryMenuItemClass}>
                                  {
                                    <span
                                      className={secondaryMenuItemLinkClass}
                                    >
                                      {childItem.label}
                                    </span>
                                  }
                                  {childItem.description ? (
                                    <span
                                      className={
                                        secondaryMenuItemDescriptionClass
                                      }
                                    >
                                      {childItem.description}
                                    </span>
                                  ) : null}
                                </button>
                              </Link>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ) : null}
                </li>
              ))
            : null}
        </ul>
      </nav>
    </>
  )
}

export default PrimaryNav
