import {storyblokEditable} from '@storyblok/react'
import classNames from 'classnames'
import {UserType, useSessionContext} from 'context/SessionContextProvider'
import {useRouter} from 'next/router'
import React, {useEffect, useState} from 'react'
import {CustomerTypeLinkStoryblok} from 'types/storyblok-types'
import {StoryTag} from 'types/types'
import {Segment} from 'util/slugHelpers/segment'

interface CustomerTypeToggleProps {
  items: CustomerTypeLinkStoryblok[]
  // Will likely be used at a later time.
  storyTags: StoryTag[]
}

function CustomerTypeToggle({items}: CustomerTypeToggleProps) {
  const router = useRouter()
  const sessionContext = useSessionContext()

  const [isBusinessSelected, setIsBusinessSelected] = useState(false)

  const individualLink = items[0]
  const businessLink = items[1]

  const individualLinkContainerClassname = classNames(
    'hover:border-dats-s1 border-b-[3px] ',
    {
      'border-dats-s1': !isBusinessSelected,
      'border-white': isBusinessSelected,
    },
  )

  const individualLinkTextClassname = classNames(
    'md:text-body text-[16px] text-dats-s3 ',
    {
      'font-bold': !isBusinessSelected,
      'font-semibold': isBusinessSelected,
    },
  )

  const businessLinkContainerClassname = classNames(
    'hover:border-dats-s1 border-b-[3px] ',
    {
      'border-dats-s1': isBusinessSelected,
      'border-white': !isBusinessSelected,
    },
  )

  const businessLinkTextClassname = classNames(
    'md:text-body text-[16px] text-dats-s3 ',
    {
      'font-bold': isBusinessSelected,
      'font-semibold': !isBusinessSelected,
    },
  )

  useEffect(() => {
    if (!sessionContext) {
      return
    }

    const lastSelectedUserType = sessionContext.selectedUserType
    if (lastSelectedUserType === UserType.individual) {
      setIsBusinessSelected(false)
    } else {
      setIsBusinessSelected(true)
    }
  }, [sessionContext])

  const shouldReloadFullPage = () => {
    return router.asPath.includes('contact')
  }

  const updateSelectedUserType = (isBusiness: boolean) => {
    setIsBusinessSelected(isBusiness)

    if (isBusiness) {
      const slug =
        router.locale === 'nl' ? Segment.professional : Segment.professionnel

      if (shouldReloadFullPage()) {
        window.location.href = `https://${process.env.NEXT_PUBLIC_DOMAIN!}/${
          router.locale ?? 'nl'
        }/${slug}`
      } else {
        router.replace(
          `https://${process.env.NEXT_PUBLIC_DOMAIN!}/${
            router.locale ?? 'nl'
          }/${slug}`,
        )
      }
    } else {
      if (shouldReloadFullPage()) {
        window.location.href = `https://${process.env.NEXT_PUBLIC_DOMAIN!}/${
          router.locale ?? 'nl'
        }/${Segment.particulier}`
      } else {
        router.replace(
          `https://${process.env.NEXT_PUBLIC_DOMAIN!}/${
            router.locale ?? 'nl'
          }/${Segment.particulier}`,
        )
      }
    }
  }

  return (
    <div className="flex h-[46px] items-center justify-between space-x-4 md:h-auto">
      <div
        className="flex w-full items-center justify-around"
        {...storyblokEditable(individualLink)}
      >
        <button
          onClick={() => updateSelectedUserType(false)}
          className={individualLinkContainerClassname}
        >
          <p className={individualLinkTextClassname}>{individualLink.label}</p>
        </button>
      </div>
      <div className="h-full w-px bg-dats-s10 md:hidden" />
      <div
        className="flex w-full items-center justify-around"
        {...storyblokEditable(businessLink)}
      >
        <button
          onClick={() => updateSelectedUserType(true)}
          className={businessLinkContainerClassname}
        >
          <p className={businessLinkTextClassname}>{businessLink.label}</p>
        </button>
      </div>
    </div>
  )
}

export default CustomerTypeToggle
