import {storyblokEditable} from '@storyblok/react'
import React, {useEffect, useState} from 'react'
import {
  LinkItemStoryblok,
  TopHeaderIndividualStoryblok,
  TopHeaderProfessionalStoryblok,
} from 'types/storyblok-types'
import Image from 'next/image'
import CustomerTypeToggle from './CustomerTypeToggle'
import LanguageToggle from 'components/LanguageToggle'
import {TranslatedSlug} from 'types/types'
import AlgoliaContainer from './AlgoliaSearch/AlgoliaContainer'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import {useSessionContext} from 'context/SessionContextProvider'
import HeaderLoginProfessional from './HeaderLoginProfessional'
import {useRouter} from 'next/router'
import linkTypeChecker from 'util/linkTypeChecker'
import Link from 'next/link'
import {Segment} from 'util/slugHelpers/segment'

interface HeaderProps {
  logoFileName?: string
  logoAlt?: string
  blok: TopHeaderProfessionalStoryblok | TopHeaderIndividualStoryblok
  translatedSlugs?: TranslatedSlug[]
}

function Header({logoFileName, logoAlt, blok, translatedSlugs}: HeaderProps) {
  const router = useRouter()

  const sessionContext = useSessionContext()
  const storyTags = blok.tags
  const searchBar = blok.search_bar?.[0]
  const noResults = blok.algolia_no_results[0]
  const faqCta = blok.faq_block[0]
  const searchValidationMessage = blok.search_validation_message[0]
  const [isSearchClicked, setIsSearchClicked] = useState<boolean>(false)

  const linkForLogo = () => {
    const locale = router.locale ?? 'nl'
    let segment = router.asPath.split('/')[1]
    if (segment) {
      if (locale === 'nl' && segment === Segment.professionnel) {
        segment = Segment.professional
      } else if (locale === 'fr' && segment === Segment.professional) {
        segment = Segment.professionnel
      }
      if (
        segment === Segment.professional ||
        segment === Segment.professionnel ||
        segment === Segment.particulier
      ) {
        return `https://${process.env.NEXT_PUBLIC_DOMAIN}/${locale}/${segment}`
      }
    }
    return `https://${process.env.NEXT_PUBLIC_DOMAIN}/${locale}/${Segment.particulier}`
  }

  useEffect(() => {
    if (!router.isReady) return

    const addXtraListener = () => {
      if (typeof window !== 'undefined' && window.XTRA) {
        window.XTRA.form.render()
      }
    }

    setTimeout(function () {
      addXtraListener()
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.locale, router.isReady])

  useEffect(() => {
    if (isSearchClicked) {
      document.body.style.overflow = 'hidden'
      return () => {
        document.body.style.overflow = 'unset'
      }
    }
  }, [isSearchClicked])

  const magnifierIcon = classNames(
    'absolute left-0 top-3.5 h-4 pl-4 text-dats-s7',
  )

  return (
    <>
      <div
        className="hidden h-[60px] w-full bg-white px-[100px] md:block"
        data-tms-navigation-section="header"
        {...storyblokEditable(blok)}
      >
        <div className="flex h-full items-center justify-between">
          <div className="top flex flex-1 items-center justify-start space-x-8">
            {logoFileName && blok.logo ? (
              <a
                href={linkForLogo()}
                aria-label="Homepage"
                className="relative h-8 w-28"
              >
                <Image
                  src={logoFileName}
                  alt={logoAlt ?? ''}
                  fill
                  className="object-cover"
                  // fill
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                />
              </a>
            ) : null}
            {blok.customer_type_link ? (
              <CustomerTypeToggle
                items={blok.customer_type_link}
                storyTags={storyTags}
              />
            ) : null}
          </div>

          <div
            onClick={() => setIsSearchClicked(true)}
            className="relative hidden h-[44px] w-80 rounded-dats border border-dats-s9 px-4 py-3 pl-10 text-sm font-semibold text-dats-s7 2xl:block"
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              size="1x"
              className={magnifierIcon}
            />
            {blok.search_bar && blok.search_bar[0].label}
          </div>

          {isSearchClicked && searchBar ? (
            <div className="fixed left-0 top-0 z-[99999] h-screen w-full overflow-y-auto bg-white px-5 text-[16px] md:flex md:flex-col md:items-center">
              <AlgoliaContainer
                searchBar={searchBar}
                setIsSearchClicked={setIsSearchClicked}
                noResults={noResults}
                faqCta={faqCta}
                logoFileName={logoFileName}
                logoAlt={logoAlt}
                searchValidationMessage={searchValidationMessage}
              />
            </div>
          ) : null}
          <div className="flex flex-1 items-center justify-end">
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              size="sm"
              onClick={() => setIsSearchClicked(true)}
              className="mr-4 hidden size-5 cursor-pointer text-dats-s3 md:block 2xl:hidden"
            />
            {blok.link_item?.map((item: LinkItemStoryblok) => (
              <Link
                key={item._uid}
                href={linkTypeChecker(item.url)}
                className="mr-4 text-sm no-underline"
                aria-label={item.label ?? ''}
                {...storyblokEditable(item)}
              >
                {item.label}
              </Link>
            ))}

            {!sessionContext.isIndividual() ? (
              <HeaderLoginProfessional
                cta_url={linkTypeChecker(blok.professional_login_link)}
              />
            ) : (
              <div className="z-[60] mx-4 hidden md:block">
                {/* @ts-ignore  */}
                <xtra-header-menu exclude-items="custModify,securityMgmt,custIdentifiers,benefits,fuelFunction,payFuncMgmt,privacy,subscriptionsModify,community,custPrefs,memoryLists">
                  {/* @ts-ignore  */}
                  <xtra-item
                    href={`/wps/portal/datscustomer/${
                      router.locale ?? 'nl'
                    }/dats24/xtra/dashboard${router.locale ?? 'nl'}`}
                    class="ico-plus"
                    label="Profiel"
                  >
                    {/* @ts-ignore  */}
                  </xtra-item>
                  {/* @ts-ignore  */}
                </xtra-header-menu>
              </div>
            )}

            {translatedSlugs || router.asPath.includes('/xtra/contact') ? (
              <LanguageToggle translatedSlugs={translatedSlugs} />
            ) : null}
          </div>
        </div>
      </div>

      {/* {xtraScriptTag} */}
    </>
  )
}

export default Header
