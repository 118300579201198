import {TranslatedSlug} from 'types/types'
import Header from './Header'
import {
  MainMenuStoryblok,
  TopHeaderIndividualStoryblok,
  TopHeaderProfessionalStoryblok,
} from 'types/storyblok-types'
import PrimaryNav from './PrimaryNav'
import MobileMenu from './MobileMenu'

type HeaderContainerProps = {
  header: TopHeaderProfessionalStoryblok | TopHeaderIndividualStoryblok
  translatedSlugs?: TranslatedSlug[]
  mainMenu: MainMenuStoryblok
}
const HeaderContainer = ({
  header,
  translatedSlugs,
  mainMenu,
}: HeaderContainerProps) => {
  return (
    <>
      <Header
        logoFileName={header.logo?.filename}
        logoAlt={header.logo?.alt}
        blok={header}
        translatedSlugs={translatedSlugs}
      ></Header>
      <div className="hidden h-[53px] border-b border-dats-s9 md:block">
        <PrimaryNav mainMenu={mainMenu} />
      </div>
      <div className="flex h-[102px] w-screen flex-col items-center justify-between md:hidden">
        <MobileMenu
          mainMenu={mainMenu}
          customerTypeData={header.customer_type_link}
          storyTags={header.tags}
          translatedSlugs={translatedSlugs}
          header={header}
        />
      </div>
    </>
  )
}

export default HeaderContainer
